@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
    --body_background: #17191c;
    --body_color: white;
    --article: white;
    --article_text:black;
  }
 
  [dark-theme="dark"]{
    --body_background: white;
    --body_color: black;
    --article: black;
    --article_text:white;


  }

  @media (min-width: 420px) and (max-width: 767px) {
    .articles {
      font-family: serif !important;
      font-size: 19px !important;
      line-height: 1.2 !important;
      color: black !important;
    }
  }
  
  @media (min-width: 768px) {
    .heights {
      height: 450px;
    }
    .articles {
      font-family: "serif";
      font-size: 22px;
      line-height: 1.2;
      color: black;
    }
  }
  
  body{
    background-color: var(--body_background);
    color: white;
  }
  .textarticle{
  color: var(--body_color);
  }
  .article_text{
    background-color: var(--article);
    color: var(--article_text);
  }

  .bg-menu{
    background-color: rgb(0, 0, 0, .7);
  }
  .bg-header{
    background-color: #17191c;
    z-index: 1000px;
  }
  .transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .hide{
    display: none;
    animation: hideMenu 8s ease-in-out ;

  }
  .blocked{
    display: block;
    animation: showMenu 0.5s ease-in-out ;
  }
  
  @keyframes showMenu {
    from{
     transform: translateX(260px);
    }
    to{
     transform: translateX(0px);
    }
   }
   @keyframes hideMenu {
     from{
      transform: translateX(-260px);
     }
     to{
      transform: translateX(-126px);
     }
    }
    
  section li{
    animation: bounce 0.2s ease-in-out;
    list-style-type: none;
  }
  section li:nth-child(2){
    animation-delay: 0.1s;
  }
  section li:nth-child(3){
    animation-delay: 0.2s;
  }
  section li:nth-child(4){
    animation-delay: 0.3s;
  }
  section li:nth-child(5){
    animation-delay: 0.4s;
  }
  section li:nth-child(6){
    animation-delay: 0.5s;
  }
  section li:nth-child(7){
    animation-delay: 0.6s;
  }
  section li:nth-child(8){
    animation-delay: 0.7s;
  }
  section li:nth-child(9){
    animation-delay: 0.8s;
  }
  section li:nth-child(10){
    animation-delay: 0.9s;
  }
  section li:nth-child(11){
    animation-delay: 1s;
  }
  section li:nth-child(12){
    animation-delay: 1.1s;
  }
  section li:nth-child(13){
    animation-delay: 1.2s;
  }
  section li:nth-child(14){
    animation-delay: 1.3s;
  }
  section ol{
    animation: bounce 0.5s ease-in-out;
    list-style-type: none;
  }
  section ol:nth-child(2){
    animation-delay: 0.1s;
  }
  section ol:nth-child(3){
    animation-delay: 0.2s;
  }
  section ol:nth-child(4){
    animation-delay: 0.3s;
  }
  section ol:nth-child(5){
    animation-delay: 0.4s;
  }
  section ol:nth-child(6){
    animation-delay: 0.5s;
  }
  section ol:nth-child(7){
    animation-delay: 0.6s;
  }
  section ol:nth-child(8){
    animation-delay: 0.7s;
  }
  section ol:nth-child(9){
    animation-delay: 0.8s;
  }
  @keyframes bounce {
    from{
     transform: translateY(-20px);
    }
    to{
     transform: translateY(0px);
    }
   }
  @keyframes rotate {
    from{
     transform: rotate(45deg);
    }
    to{
     transform: rotate(360deg);
    }
   }
.careers{
  position: relative;
}
  .careers::after{
    content: '';
    position: absolute;
    bottom: 8px;
    height: 1.5px;
    width: 80%;
    left: 8px;
    background-color: white;
    transition: 0.3s ease-in-out;
    transform: scaleX(0);
  }
  .careers:hover::after{
    transform: scaleX(1);
    
  }
  .about{
    position: relative;
  }
  .about::after{
    content: '';
    position: absolute;
    bottom: 8px;
    height: 1.5px;
    width: 74%;
    left: 8px;
    background-color: white;
    transition: 0.3s ease-in-out;
    transform: scaleX(0);
  }
  .about:hover::after{
    transform: scaleX(1);
    
  }
  .contact{
    position: relative;
  }
  .contact::after{
    content: '';
    position: absolute;
    bottom: 8px;
    height: 1.5px;
    width: 78%;
    left: 8px;
    background-color: white;
    transition: 0.3s ease-in-out;
    transform: scaleX(0);
  }
  .contact:hover::after{
    transform: scaleX(1);
    
  }

  .logistics{
    position: relative;
  }
  .logistics::after{
    content: '';
    position: absolute;
    bottom: 8px;
    height: 1.5px;
    width: 90%;
    left: 8px;
    background-color: white;
    transition: 0.3s ease-in-out;
    transform: scaleX(0);
  }
  .logistics:hover::after{
    transform: scaleX(1);
    
  }

  .article{
    position: relative;
  }
  .article::after{
    content: '';
    position: absolute;
    bottom: 8px;
    height: 1.5px;
    width: 57%;
    left: 8px;
    background-color: white;
    transition: 0.3s ease-in-out;
    transform: scaleX(0);
  }
  .article:hover::after{
    transform: scaleX(1);
    
  }
  .tech{
    position: relative;
  }
  .tech::after{
    content: '';
    position: absolute;
    bottom: 8px;
    height: 1.5px;
    width: 92%;
    left: 8px;
    background-color: white;
    transition: 0.3s ease-in-out;
    transform: scaleX(0);
  }
  .tech:hover::after{
    transform: scaleX(1);
    
  }
  .logistic{
    position: relative;
  }

  .logistic::after{
    content: '';
    position: absolute;
    bottom: 8px;
    height: 1.5px;
    width: 90%;
    left: 8px;
    background-color: white;
    transition: 0.3s ease-in-out;
    transform: scaleX(0);
  }

.logistic:hover::after{
  transform: scaleX(1);

}
  .ride{
    position: relative;
  }
  .ride::after{
    content: '';
    position: absolute;
    bottom: 8px;
    height: 1.5px;
    width: 83%;
    left: 8px;
    background-color: white;
    transition: 0.3s ease-in-out;
    transform: scaleX(0);
  }
  .ride:hover::after{
    transform: scaleX(1);
    
  }

  .star{
    position: relative;
  }
  .star::after{
    content: '';
    position: absolute;
    bottom: 8px;
    height: 1.5px;
    width: 85%;
    left: 8px;
    background-color: white;
    transition: 0.3s ease-in-out;
    transform: scaleX(0);
  }
  .star:hover::after{
    transform: scaleX(1);
    
  }
  
  .starlink{
    position: relative;
  }
  .starlink::after{
    content: '';
    position: absolute;
    bottom: 8px;
    height: 1.5px;
    width: 80%;
    left: 8px;
    background-color: white;
    transition: 0.3s ease-in-out;
    transform: scaleX(0);
  }
  .starlink:hover::after{
    transform: scaleX(1);
    
  }
  
  .shop{
    position: relative;
  }
  .shop::after{
    content: '';
    position: absolute;
    bottom: 8px;
    height: 1.5px;
    width: 65%;
    left: 8px;
    background-color: white;
    transition: 0.3s ease-in-out;
    transform: scaleX(0);
  }
  .shop:hover::after{
    transform: scaleX(1);
    
  }
  .prev,
  .next {
    position: fixed;
    top: 250px;
    width: 1.25rem;
    height: 1.25rem;
    display: inline-flex;
    translate: -50px;
    place-items: center;
    border-color: transparent;
    font-size: 1rem;
    cursor: pointer;
  }
  .prev:hover,
  .next:hover {
    background: rgb(73, 73, 91);
    border-radius: 20px;
  }
  .prev{
    left: 80px;
  }
  .next {
    right: -25px;
  }
  .prev,
  .next {
    width: 2rem;
    height: 2rem;
    font-size: 1.5rem;
    color: white;
  }
article {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: var(--transition);
}

article.activeSlide {
  opacity: 1;
  transform: translateX(0);
}
article.lastSlide {
  transform: translateX(-100%);
}
article.nextSlide {
  transform: translateX(100%);
}

main.activeSlide {
  opacity: 1;
  transform: translateX(0);
}
main.lastSlide {
  transform: translateX(-100%);
}
main.nextSlide {
  transform: translateX(100%);
}

.wrapper{
  display: inline-flex;
  align-items: center;
  margin: 0px auto;
}
.static{
color: white;
font-weight: bold;
}
.dynamic{
height: 40px;
overflow: hidden;
line-height: 26px;
}
ol main::after{
content: '';
position: absolute;
height: 100px;
width: 100%;
left: 0;
border-left: 2px solid white;
background-color: white;
animation: typing 5s steps(57) infinite;
}
@keyframes typing{
100%{
  left: 100%;
  margin: 0 -25px 0 10px;
}
}
ol main{
position: relative;
margin-top: -1.5px;
text-align: center;
}
ol{
list-style: none;
font-weight: bold;
color: white;
/*animation: topping 6s steps(3) infinite;
position: relative;
top: 0;*/
}
.bg-logistic{
  background-color: transparent;
  animation: logistic 1s ease-in-out;

}

@keyframes logistic {
  
  from{
    transform: scale(.1);
  }
  to{
    transform: scale(1.);
  }
}

.toggle{
  background-color: rgb(132, 132, 142);
  height: 2px;
  padding: 2px;margin-top: 7px;
  margin-bottom: -7px;
}

.roll{
  rotate: 90deg;
}
.hover-bg{
  background-color: #000000b3;
}
.bg-line{
  background-color: #313030;
  height: 0.5px;
}
.bg-flip{
  background-color: rgb(0, 0, 0, .9);
}
.flip{
 animation: flipin 0.5s ease-in-out;
}

@keyframes flipin {
  from{
   transform: rotateY(180deg)
  }
  to{
    transform: rotateY(0);
  }
}
.opacitys{
  background-color: rgb(0, 0, 0, .3);
}
.underline:hover{
text-decoration: underline;
transition: all 2s ease-in-out;
color: rgb(141, 141, 245);
text-decoration-color: rgb(175, 175, 235);
}
.underline{
  transition: all 4s ease-in-out;
  text-decoration: none;
  }
  
  .abouthover:hover{

    background-color: white;
    color: black;
  }
  .abouthover{
    transition: all 2s ease-in-out;
  }
  .loading{
    content: '';
    display: block;
    width: 100px;
    height: 2px;
    background-color: green;
    border: 2px solid rgb(83, 83, 84);
    animation: rotat 2s ease-in-out infinite;
  }
  @keyframes rotat {
  
    from {
      transform: translateX(0px);
    }
  
    to{
      transform: translateX(60px);
    }
  }

.scales{
  animation: scale 2s ease-in-out infinite;
  translate: 50px;
}

@keyframes scale {
from{
  transform: scale(1.1);
}  
to{
  transform: scale(1.2);
}
}
@media print {
  .no-print {
    display: none !important;
  }
  .absolute {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
  }
}
.ecoreme{
  background-color: #17191c;
}